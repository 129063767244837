import {
  Hiking,
  ForestOutlined,
  Restaurant,
  LiquorOutlined,
  PasswordOutlined,
  LocalGroceryStoreOutlined,
  InfoOutlined,
  TheaterComedyOutlined,
  PaletteOutlined,
  Update,
  AccountBalanceOutlined,
} from "@mui/icons-material";

export default function Village() {
  return (
    <div className="village" id="village">
      <h2>Le village</h2>
      <p>
        Village de caractère
        <br />
        A 1h de Lyon, Saint-Etienne, Clermont, 15 minutes de Roanne.
        <br />
        Nous sommes situés au coeur de Saint-Jean-Saint-Maurice.
      </p>

      <div className="village_bloc">
        <div className="village_bloc_1 village_bloc_prop"></div>
        <div className="village_bloc_2 village_bloc_prop"></div>
        <div className="village_bloc_3 village_bloc_prop"></div>
        <div className="village_bloc_4 village_bloc_prop"></div>
      </div>
      <div className="village_bloc_icon">
        <div className="village_bloc_icon_column">
          <div className="village_bloc_icon_column_bloc">
            <p className="village_icon">
              <Hiking fontSize="large" />
            </p>
            <p className="village_p">
              Des kilomètres de chemins balisés dont le chemin de St Jacques de
              Compostelle
            </p>
          </div>
          <div className="village_bloc_icon_column_bloc">
            <p className="village_icon">
              <ForestOutlined fontSize="large" />
            </p>
            <p className="village_p">
              Label zone Natura 2000 :<br />
              Préservation de la faune, la flore, les habitats naturels
            </p>
          </div>
        </div>
        <div className="village_bloc_icon_column">
          <div className="village_bloc_icon_column_bloc">
            <p className="village_icon">
              <Restaurant fontSize="large" />
            </p>
            <p className="village_p">2 restaurants à 800m de chez nous</p>
          </div>
          <div className="village_bloc_icon_column_bloc">
            <p className="village_icon">
              <LiquorOutlined fontSize="large" />
            </p>
            <p className="village_p">
              Domaines viticoles Côte Roannaise AOC
              <br />
              Oenotourisme
            </p>
          </div>
          <div className="village_bloc_icon_column_bloc">
            <p className="village_icon">
              <PasswordOutlined fontSize="large" />
            </p>
            <p className="village_p">Restaurant Troisgros à 10 minutes</p>
          </div>
          <div className="village_bloc_icon_column_bloc">
            <p className="village_icon">
              <LocalGroceryStoreOutlined fontSize="large" />
            </p>
            <p className="village_p">
              Grandes surfaces, pharmacie, essence à 5 minutes
            </p>
          </div>
        </div>
        <div className="village_bloc_icon_column village_bloc_icon_column3">
          <div className="village_bloc_icon_column_bloc">
            <p className="village_icon">
              <InfoOutlined fontSize="large" />
            </p>
            <p className="village_p">Point d'infos touristique</p>
          </div>
          <div className="village_bloc_icon_column_bloc">
            <p className="village_icon">
              <TheaterComedyOutlined fontSize="large" />
            </p>
            <p className="village_p">Spectacles, expos, animations</p>
          </div>
          <div className="village_bloc_icon_column_bloc">
            <p className="village_icon">
              <PaletteOutlined fontSize="large" />
            </p>
            <p className="village_p">
              Pole métiers d'art
              <br />
              Stages
            </p>
          </div>
        </div>
        <div className="village_bloc_icon_column">
          <div className="village_bloc_icon_column_bloc">
            <p className="village_icon">
              <AccountBalanceOutlined fontSize="large" />
            </p>
            <p className="village_p">Contempler des siècles d'histoire</p>
          </div>
          <div className="village_bloc_icon_column_bloc">
            <p className="village_icon">
              <Update fontSize="large" />
            </p>
            <p className="village_p">
              {" "}
              L'homme de néandertal vivait aux abords du fleuve
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
