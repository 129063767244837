import Navbar from "./components/Navbar";
import { BrowserRouter } from "react-router-dom";
import Slider from "./components/Slider";
import Slowlife from "./components/Slowlife";
import Presentation from "./components/Presentation";
import Esprit from "./components/Esprit";
import Hebergement from "./components/Hebergement";
import Village from "./components/Village";
import Deco from "./components/Deco";
import Contact from "./components/Contact";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Slider />
        <Slowlife />
        <Presentation />
        <Hebergement />
        <Esprit />
        <Village />
        <Deco />
        <Contact />
      </BrowserRouter>
    </div>
  );
}

export default App;
