import { HashLink } from "react-router-hash-link";
export default function Navbar() {
  return (
    <div className="navbar">
      <div className="logo">
        Les Rêveries de l'<span>E</span>scargot
      </div>
      <label for="mobile_menu" id="menu_label">MENU</label>
      <input type="checkbox" id="mobile_menu"/>
      <div className="menu">
        <ul>
          <li className="menu--left">
            <HashLink
              to="/path#hebergement"
              smooth={true}
              duration={1000}
              spy={true}
              style={{ textDecoration: "none" }}
            >
              L'hébergement
            </HashLink>
          </li>
          <li>
            <HashLink
              to="/path#esprit"
              smooth={true}
              duration={1000}
              spy={true}
              style={{ textDecoration: "none" }}
            >
              L'esprit des lieux
            </HashLink>
          </li>
          <li>
            <HashLink
              to="/path#village"
              smooth={true}
              duration={1000}
              spy={true}
              style={{ textDecoration: "none" }}
            >
              Le village
            </HashLink>
          </li>
          <li><HashLink
              to="/path#deco"
              smooth={true}
              duration={1000}
              spy={true}
              style={{ textDecoration: "none" }}
            >
              La déco
            </HashLink></li>
          <li className="menu--right"><HashLink
              to="/path#contact"
              smooth={true}
              duration={1000}
              spy={true}
              style={{ textDecoration: "none" }}
            >
              Réservations / Bon cadeau
            </HashLink></li>
        </ul>
      </div>
    </div>
  );
}
