import {
  Bed,
  BathtubOutlined,
  DeckOutlined,
  CellWifi,
  CountertopsOutlined,
  Height,
  Tv,
  BakeryDining,
  PanoramaOutlined,
  PedalBike,
  LocalParking,
  AcUnit,
  PetsOutlined,
  DryCleaning,
  TableRestaurant,
  Euro,
} from "@mui/icons-material";

export default function Hebergement() {
  return (
    <div className="hebergement" id="hebergement">
      <h2>L'hébergement</h2>
      <p>Vous êtes au calme et on vous rend la vie belle !</p>
      <div className="hebergement_bloc">
        <div className="hebergement_bloc_1 hebergement_bloc_prop"></div>
        <div className="hebergement_bloc_2 hebergement_bloc_prop"></div>
        <div className="hebergement_bloc_3 hebergement_bloc_prop"></div>
        <div className="hebergement_bloc_4 hebergement_bloc_prop"></div>
      </div>
      <div className="hebergement_bloc_icon">
        <div className="hebergement_bloc_icon_column">
          <div className="hebergement_bloc_icon_column_bloc">
            <p className="hebergement_icon">
              <Bed fontSize="large" />
            </p>
            <p className="hebergement_p">Lit 160</p>
          </div>
          <div className="hebergement_bloc_icon_column_bloc">
            <p className="hebergement_icon">
              <BathtubOutlined fontSize="large" />
            </p>
            <p className="hebergement_p">Baignoire balnéo (wc séparés)</p>
          </div>
          <div className="hebergement_bloc_icon_column_bloc">
            <p className="hebergement_icon">
              <DeckOutlined fontSize="large" />
            </p>
            <p className="hebergement_p">Terrasse donnant sur zone piétonne</p>
          </div>
          <div className="hebergement_bloc_icon_column_bloc">
            <p className="hebergement_icon">
              <PetsOutlined fontSize="large" />
            </p>
            <p className="hebergement_p">Petit chien avec supplément</p>
          </div>
        </div>
        <div className="hebergement_bloc_icon_column">
          <div className="hebergement_bloc_icon_column_bloc">
            <p className="hebergement_icon">
              <CellWifi fontSize="large" />
            </p>
            <p className="hebergement_p">Wifi gratuite</p>
          </div>
          <div className="hebergement_bloc_icon_column_bloc">
            <p className="hebergement_icon">
              <CountertopsOutlined fontSize="large" />
            </p>
            <p className="hebergement_p">Cuisine</p>
          </div>
          <div className="hebergement_bloc_icon_column_bloc">
            <p className="hebergement_icon">
              <Height fontSize="large" />
            </p>
            <p className="hebergement_p">
              Mezzanine <br />
              De 1,30m à 2,10 m
            </p>
          </div>
          <div className="hebergement_bloc_icon_column_bloc">
            <p className="hebergement_icon">
              <DryCleaning fontSize="large" />
            </p>
            <p className="hebergement_p">Draps et serviettes fournis</p>
          </div>
        </div>
        <div className="hebergement_bloc_icon_column">
          <div className="hebergement_bloc_icon_column_bloc">
            <p className="hebergement_icon">
              <Tv fontSize="large" />
            </p>
            <p className="hebergement_p">TV</p>
          </div>
          <div className="hebergement_bloc_icon_column_bloc">
            <p className="hebergement_icon">
              <BakeryDining fontSize="large" />
            </p>
            <p className="hebergement_p">
              Petit déjeuner panier livré à domicile
            </p>
          </div>
          <div className="hebergement_bloc_icon_column_bloc">
            <p className="hebergement_icon">
              <PanoramaOutlined fontSize="large" />
            </p>
            <p className="hebergement_p">Vue sur montagne et jardin</p>
          </div>
          <div className="hebergement_bloc_icon_column_bloc">
            <p className="hebergement_icon">
              <TableRestaurant fontSize="large" />
            </p>
            <p className="hebergement_p">1 table et 2 chaises en terrasse</p>
          </div>
        </div>
        <div className="hebergement_bloc_icon_column" id="bloc_4">
          <div className="hebergement_bloc_icon_column_bloc">
            <p className="hebergement_icon">
              <PedalBike fontSize="large" />
            </p>
            <p className="hebergement_p">Garage à vélo</p>
          </div>
          <div className="hebergement_bloc_icon_column_bloc">
            <p className="hebergement_icon">
              <LocalParking fontSize="large" />
            </p>
            <p className="hebergement_p">Parking gratuit</p>
          </div>
          <div className="hebergement_bloc_icon_column_bloc">
            <p className="hebergement_icon">
              <AcUnit fontSize="large" />
            </p>
            <p className="hebergement_p">Climatisation</p>
          </div>
          <div className="hebergement_bloc_icon_column_bloc">
            <p className="hebergement_icon">
              <Euro fontSize="large" />
            </p>
            <p className="hebergement_p">Taxe de séjour: selon tarif en vigueur</p>
          </div>
        </div>
      </div>
    </div>
  );
}
