export default function Esprit() {
  return (
    <div className="esprit" id="esprit">
      <h2>L'esprit des lieux</h2>
      <div className="esprit_bloc">
        <div className="esprit_bloc_1 esprit_bloc_prop"></div>
        <div className="esprit_bloc_2 esprit_bloc_prop"></div>
        <div className="esprit_bloc_3 esprit_bloc_prop"></div>
        <div className="esprit_bloc_4 esprit_bloc_prop"></div>
      </div>
      <p>
        Nous revendiquons un art de vivre « à la française » dans cette ancienne
        ferme vigneronne du XVIIIème siècle.
        <br />
        <div className="space_mobile">
          <br />
        </div>{" "}
        Nous vous proposons des produits bio et fait maison pour les petits
        déjeuners, des créations uniques et originales pour les objets déco.
        <br />
        <div className="space_mobile">
          <br />
        </div>
        Nous sommes sensibles au patrimoine, à l’art, à la nature.
        <br />
        <div className="space_mobile">
          <br />
        </div>
        Nature dont nous apprenons chaque jour.
        <br />
        <div className="space_mobile">
          <br />
        </div>
        Notre dernière découverte: la permaculture.
      </p>
    </div>
  );
}
