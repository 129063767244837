export default function Presentation() {
  return (
    <div className="presentation">
      <div className="portrait"></div>
      <p>
        Notre truc à nous :
        <br />
        <br />
        Vous offrir un lieu qui va vous permettre de vous reconnecter à votre
        propre rythme, dans la simplicité.
        <br />
        <br />
        Vous faire découvrir la nature protégée par le label "Natura 2000" qui
        assure la biodiversité et le chant des oiseaux.
        <br />
        <br />
        Partager en toute indépendance, le temps d'un séjour, notre ferme
        vigneronne. Nous l'avons rénovée de nos mains, après une reconversion
        professionnelle.
        <br />
        <br />
        Vous proposer une déco changeante et renouvelée puisque vous pouvez
        repartir avec votre coup de coeur pour une création réalisée ici ou par
        des artisans locaux.
      </p>
    </div>
  );
}
