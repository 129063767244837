import {
  AlarmOff,
  AlarmOn,
  BedOutlined,
  CardGiftcard,
  DryCleaning,
  EmailOutlined,
  Euro,
  Instagram,
  LocalPhoneOutlined,
  Person,
  Pets,
} from "@mui/icons-material";

export default function Contact() {
  return (
    <div className="contact" id="contact">
      <h2>Contact / Bon cadeau</h2>
      <div className="contact_bloc">
        <div className="contact_bloc_left">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <EmailOutlined /> &nbsp; lesreveriesdelescargot@gmail.com
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <LocalPhoneOutlined />
            &nbsp; 06 41 81 44 50
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <CardGiftcard />
            &nbsp; Carte cadeau
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <AlarmOn />
            &nbsp;Arrivée : 17 heures
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <AlarmOff />
            &nbsp;Départ : 11 heures
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Instagram />
            <a
              href="https://www.instagram.com/les_reveries_de_l_escargot/"
              target="_blank"
              rel="noreferrer"
            >
              &nbsp;Instagram
            </a>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: "10px",
            }}
          >
            <div className="euro">
              <Euro />
            </div>
            <div>
              Acompte de 30% à la réservation par chèque.
            </div>
          </div>
        </div>
        <div className="contact_vertical_line"></div>
        <div className="contact_bloc_right">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <Person />- 80 € 1 nuit (petit déjeuner inclus)
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <Person /> <Person />- 95 € - 1 nuit (petits déjeuners inclus)
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <BedOutlined />- 20 € - lit supplémentaire
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <Pets />- 7 € - Chien
          </div>
          <hr />
          <div className="space_mobile">
            <br />
          </div>
          <div className="contact_vertical_line mobile_line"></div>
          <div className="space_computer">
            <br />
          </div>
          Location possible à la semaine en gîte.
          <br />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <Person /> <Person />- toute l'année 350 €
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <Person /> <Person />- haute saison 380 €
          </div>
          <br />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <DryCleaning />- Draps et serviettes fournis gratuitement
          </div>
          <br />
          <hr />
          <br />
          <span>PLAN D’ACCÈS</span>
          <iframe
            title="maps"
            width="400"
            height="200"
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=les%20reveries%20de%20l'escargot&t=&z=13&ie=UTF8&iwloc=&output=embed"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
          ></iframe>
          <br />
        </div>
      </div>
    </div>
  );
}
