export default function Deco() {
  return (
    <div className="deco" id="deco">
      <h2>La déco</h2>
      <p>
        Nous vous proposons une déco composée de créations originales réalisées par des artistes locaux.
        <br />
        Vous êtes dans une maison d'artiste avec une maitresse des lieux aquarelliste.
        <br />
        </p>
        <div className="deco_bloc">
        <div className="deco_bloc_1 deco_bloc_prop"></div>
        <div className="deco_bloc_2 deco_bloc_prop"></div>
        <div className="deco_bloc_3 deco_bloc_prop"></div>
        <div className="deco_bloc_4 deco_bloc_prop"></div>
      </div>

          <br />
        </div>
  );
}
