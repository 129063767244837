import {
  AccountBalance,
  Brush,
  EmojiFoodBeverage,
  House,
} from "@mui/icons-material";

export default function Slowlife() {
  return (
    <div className="slowlife">
      <h2>EN MODE SLOWLIFE</h2>
      <img src="./snail.png" alt="" />
      <h4>
        ECHAPPEZ À LA FOULE, PROFITEZ DU
        <br />
        GRAND AIR EN ZONE NATURA 2000
      </h4>
      <div className="slowlife--cards">
        <div className="card">
          <House className="icon" />
          <h5>UN HEBERGEMENT COMPLET ET INDÉPENDANT</h5>
        </div>
        <div className="card">
          <AccountBalance className="icon" />
          <h5>UN LIEU AUTHENTIQUE DANS UN VILLAGE MÉDIÈVAL</h5>
        </div>
        <div className="card">
          <EmojiFoodBeverage className="icon" />
          <h5>UN PETIT-DEJEUNER LOCAL ET BIO SERVI EN CHAMBRE</h5>
        </div>
        <div className="card">
          <Brush className="icon" />
          <h5>UNE DECORATION UNIQUE QUE VOUS POUVEZ ACHETER</h5>
        </div>
      </div>
    </div>
  );
}
